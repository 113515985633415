/*
Template: Qloud  - Qloud Landing Page WordPress Theme
Author: iqonicthemes.in
Version: 2.6
Design and Developed by: iqonicthemes.in

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template. Please do not change anything here! write in a custom.css file if required!
*/

@import "_custom-properties.css";
@import "_reset.css";
@import "_typography.css";
@import "_elements.css";
@import "_links.css";
@import "_blocks.css";
@import "_header.css";
@import "_burger-menu.css";
@import "_search.css";
@import "_media.css";
@import "_footer.css";
@import "_select2.css";
@import "_adminbar.css";




/*
Template: Talkie
Author: iqonicthemes.in
Version: 1.0
Design and Developed by: iqonicthemes.in

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template. Please do not change anything here! write in a custom.css file if required!

*/

/*================================================
[  Table of contents  ]
================================================


:: General
:: Clearing floats
:: Back to Top


======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
                              General
-----------------------------------------------------------------------*/
/*--------------------------------------------
			  Scrollbar
----------------------------------------------------*/
/* width */
::-webkit-scrollbar {
	width: 0.25em;
	height: 0.25em;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 0.313em;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--primary-color);
	border-radius: 0.313em;
}

.admin-color-fresh #redux-header, .wp-customizer #redux-header {
	background: #2b2b2b;
	border-color: #19beec;
}

.redux-sidebar .redux-group-menu li a {
	background: #2b2b2b;
	opacity: 1;
	color: #a9abae;
	border-bottom-color: rgba(255, 255, 255, .08);
}

.admin-color-fresh .redux-sidebar .redux-group-menu li.activeChild.hasSubSections a, .wp-customizer .redux-sidebar .redux-group-menu li.activeChild.hasSubSections a {
	background: #2b2b2b;
	color: #19beec;
	text-shadow: none;
}

.redux-container #redux-header .display_header {
	padding: 20px;
}

.redux-sidebar .redux-group-menu li a {
	padding: 15px;
}

.redux-sidebar .redux-group-menu li a span {
	margin-left: 30px;
}

.admin-color-fresh .redux-sidebar .redux-group-menu li.active.hasSubSections ul.subsection li a, .admin-color-fresh .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li a, .wp-customizer .redux-sidebar .redux-group-menu li.active.hasSubSections ul.subsection li a, .wp-customizer .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li a {
	padding: 10px;
}

.redux-sidebar .redux-group-menu li a:hover, .admin-color-fresh .redux-sidebar .redux-group-menu li.active.hasSubSections ul.subsection li a:hover, .admin-color-fresh .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li a:hover, .wp-customizer .redux-sidebar .redux-group-menu li.active.hasSubSections ul.subsection li a:hover, .wp-customizer .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li a:hover {
	color: #19beec;
	background: #2b2b2b;
}

.admin-color-fresh .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li.active a, .wp-customizer .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li.active a {
	background: #19beec;
}

.admin-color-fresh .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li a, .wp-customizer .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li a {
	background: #2b2b2b;
}

.admin-color-fresh .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li a {
	color: #a9abae;
}

.admin-color-fresh .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li.active a, .wp-customizer .redux-sidebar .redux-group-menu li.activeChild.hasSubSections ul.subsection li.active a {
	text-shadow: none;
	color: var(--white-color);
}

.redux-sidebar ul .subsection {
	border: 1px solid #19beec;
}

.redux-container-text input, .redux-main input.large-text {
	height: 45px;
	padding: 0 15px;
	margin-bottom: 15px;
	box-shadow: none;

}

.redux-main .button {
	font-size: 14px;
	height: 45px;
	line-height: 45px;
	padding: 0 35px;
	box-shadow: none;
	color: var(--white-color);
	text-shadow: none;
	border-radius: 0;
	margin: 0 5px;
	background: #2b2b2b;
}

.redux-container-switch .switch-options label {
	line-height: 45px;
	cursor: pointer;
	border-radius: 0;
	height: 45px;
	padding: 0 25px;
}

.redux-main .button:hover {
	background: #19beec;
	color: var(--white-color);
	border-color: #19beec;
}

.redux-main .button.remove-image {
	background: #19beec;
	color: var(--white-color);
	border-color: #19beec;
}

.sp-replacer {
	padding: 10px;
}

.redux-container .redux-group-tab h3 {
	text-transform: capitalize;
}

.redux-container-switch .cb-disable span, .redux-container-switch .cb-enable span {
	line-height: 43px;
}

.redux-main .admin-color-fresh .redux-container-switch .cb-disable, .redux-main .admin-color-fresh .redux-container-switch .cb-enable, .redux-main .admin-color-fresh .ui-state-default, .admin-color-fresh .ui-widget-content .ui-state-default, .admin-color-fresh .ui-widget-header .ui-state-default, .wp-customizer .redux-container-switch .cb-disable, .wp-customizer .redux-container-switch .cb-enable, .wp-customizer .ui-state-default, .wp-customizer .ui-widget-content .ui-state-default, .wp-customizer .ui-widget-header .ui-state-default {
	background: #2b2b2b !important;
	border-color: #2b2b2b !important;
	color: var(--white-color);
}

.redux-main .button.remove-image:hover {
	background: #2b2b2b;
	border-color: #2b2b2b;
	color: var(--white-color);
}

.wp-core-ui .redux-main .button.wp-color-result {
	padding: 0 0 0 60px;
}

.wp-core-ui .redux-main .button-secondary:focus, .wp-core-ui .redux-main .button-secondary:hover, .wp-core-ui .redux-main .button.focus, .wp-core-ui .redux-main .button.hover, .wp-core-ui .redux-main .button:focus, .wp-core-ui .redux-main .button:hover {
	background: #19beec;
	border-color: #19beec;
	color: var(--white-color);
	text-shadow: none;
}

.redux-main #redux-sticky #info_bar {
	height: 45px;

}

.redux-container #info_bar {
	padding: 15px;
}

.redux-container-image_select ul.redux-image-select li {
	padding: 5px 5px 15px 5px;
}

.redux-main .redux-option-image {
	margin-bottom: 15px;
}

.redux-main .input-prepend, .redux-main .input-prepend input, .redux-container-sortable input {
	box-shadow: none;
	height: 45px;
}

.redux-container-sortable input {
	padding: 15px;
}

.redux-main .input-append .add-on, .redux-main .input-prepend .add-on {
	height: 43px;
	line-height: 43px;
	padding: 0 5px;
}

.redux-main #redux-sticky #info_bar .expand_options {
	margin-top: 7px;
}

.redux-container .expand_options {
	height: 30px;
	width: 30px;
	background-position: 3px -21px;
}

.redux-main .wp-media-buttons span.wp-media-buttons-icon {
	width: auto;
	height: 40px;
	vertical-align: middle;
	margin-right: 10px;
}

.redux-main .wp-media-buttons .insert-media {
	margin-bottom: 15px;
	padding: 0 30px;
}

.redux-main textarea {
	padding: 15px;
}

.redux-main .button:hover span.wp-media-buttons-icon {
	color: var(--white-color);
}

.redux-container .ui-buttonset .ui-button {
	line-height: 44px;
	height: 45px;
	padding: 0 25px;
	vertical-align: middle;
	text-shadow: none;
	border-radius: 0;
}

.redux-main .wp-core-ui .button-secondary:focus, .redux-main .wp-core-ui .button.focus, .redux-main .wp-core-ui .button:focus, .redux-main input[type=checkbox]:focus, .redux-main input[type=color]:focus, .redux-main input[type=date]:focus, .redux-main input[type=datetime-local]:focus, .redux-main input[type=datetime]:focus, .redux-main input[type=email]:focus, .redux-main input[type=month]:focus, .redux-main input[type=number]:focus, .redux-main input[type=password]:focus, .redux-main input[type=radio]:focus, .redux-main input[type=search]:focus, .redux-main input[type=tel]:focus, .redux-main input[type=text]:focus, .redux-main input[type=time]:focus, .redux-main input[type=url]:focus, .redux-main input[type=week]:focus, .redux-main select:focus, .redux-main textarea:focus {
	box-shadow: none;
	border-color: #19beec;
}

.redux-sidebar .redux-group-menu li.active a {
	background: #19beec;
}

.admin-color-fresh .redux-sidebar .redux-group-menu li.active a {
	color: var(--white-color);
}

.admin-color-fresh .redux-sidebar .redux-group-menu li.active:hover a {
	color: #19beec;
}

.form-table .redux-container-text input {
	margin-bottom: 0;
}

.wp-admin .composer-switch a.wpb_switch-to-composer, .wp-admin .composer-switch a.wpb_switch-to-front-composer, .wp-admin .composer-switch a:visited.wpb_switch-to-front-composer {
	background: #2b2b2b;
}

#wpb_visual_composer .vc_navbar .vc_icon-btn:hover, .wp-admin .composer-switch a.wpb_switch-to-composer:hover, .wp-admin .composer-switch a:visited.wpb_switch-to-composer:hover, .wp-admin .composer-switch a.wpb_switch-to-front-composer:hover, .wp-admin .composer-switch a:visited.wpb_switch-to-front-composer:hover {
	background-color: #19beec;
}

.admin-color-fresh .redux-container-image_select .redux-image-select-selected img, .wp-customizer .redux-container-image_select .redux-image-select-selected img {
	border-color: #19beec;
}

.wp-admin .composer-switch .logo-icon {
	background-color: #2b2b2b;
}

.metabox-composer-content .vc_ui-button.vc_ui-button-info {
	background: #19beec !important;
}

#wpb_visual_composer .vc_navbar {
	background: #2b2b2b;
	border-color: #2b2b2b;
}

.wp-admin .composer-switch .logo-icon:hover {
	background-color: #19beec;
}

.wp-admin .vc_ui-panel-header-container {
	background: #2b2b2b;
}

.wp-admin .vc_add-element-container .wpb-content-layouts li:hover {
	border-color: #19beec;
}

.wp-admin .vc_ui-button.vc_ui-button-default {
	background: #2b2b2b;
}

.wp-admin .vc_ui-button.vc_ui-button-default:focus, .wp-admin .vc_ui-button.vc_ui-button-default:hover {
	background-color: #19beec;
}

.wp-admin .vc_add-element-container .wpb-content-layouts li {
	background: transparent;
}

.wp-core-ui .redux-main .form-table .button-primary {
	background: #2b2b2b;
	border-color: #2b2b2b;
	text-shadow: none;
	box-shadow: none;
}

.wp-core-ui .redux-main .form-table .button-primary:hover {
	background: #19beec;
	border-color: #19beec;
}

.wp-core-ui .redux-main .button-primary.active, .wp-core-ui .redux-main .button-primary.active:focus, .wp-core-ui .redux-main .button-primary.active:hover, .wp-core-ui .redux-main .button-primary:active, .admin-color-fresh .redux-container-switch .cb-enable.selected, .admin-color-fresh .redux-field-container .ui-buttonset .ui-state-active, .wp-customizer .redux-container-switch .cb-enable.selected, .wp-customizer .redux-field-container .ui-buttonset .ui-state-active {
	box-shadow: none !important;
	background: #19beec !important;
	border-color: #19beec !important;
}

.wp-core-ui .redux-main .button-secondary:focus, .wp-core-ui .redux-main .button.focus, .wp-core-ui .redux-main .button:focus, .wp-core-ui .redux-main .button-primary.focus, .wp-core-ui .redux-main .button-primary.hover, .wp-core-ui .redux-main .button-primary:focus, .wp-core-ui .redux-main .button-primary:hover {
	border-color: #19beec !important;
	background: #19beec !important;
	box-shadow: none !important;
}

.vc_add-element-container .wpb-content-layouts a {
	color: #2b2b2b;
}

.vc_el-container #vc_infobox_bgoverlay .vc_element-icon, .vc_el-container>#wooboxbanner>.vc_element-icon, .vc_el-container>#wooboxblog>.vc_element-icon, .vc_el-container>#wooboxButton>.vc_element-icon, .vc_el-container>#wooboxcircle>.vc_element-icon, .vc_el-container>#wooboxclient>.vc_element-icon, .vc_el-container>#wooboxcounter>.vc_element-icon, .vc_el-container>#wooboxList>.vc_element-icon, .vc_el-container>#wooboxoffers>.vc_element-icon, .vc_el-container>#wooboxofferspro>.vc_element-icon, .vc_el-container>#woobox_deal_offer>.vc_element-icon, .vc_el-container>#wooboxFancybox>.vc_element-icon, .vc_el-container>#wooboxFAQ>.vc_element-icon, .vc_el-container>#wooboxfeaturedpro>.vc_element-icon, .vc_el-container>#wooboxfeedback>.vc_element-icon, .vc_el-container>#wooboxflinglayer>.vc_element-icon, .vc_el-container>#wooboxpricing>.vc_element-icon, .vc_el-container>#wooboxlistboxs>.vc_element-icon, .vc_el-container>#wooboxslider>.vc_element-icon, .vc_el-container>#wooboxsubscribe>.vc_element-icon, .vc_el-container>#wooboxsupport>.vc_element-icon, .vc_el-container>#wooboxtabs>.vc_element-icon, .vc_el-container>#wooboxteam>.vc_element-icon, .vc_el-container>#woobox_timer>.vc_element-icon, .vc_el-container>#wooboxtitle>.vc_element-icon, .vc_el-container>#wooboxvideo_offer>.vc_element-icon, .vc_el-container>#wooboxvideo>.vc_element-icon, .vc_el-container>#wooboxworkbox>.vc_element-icon, .vc_el-container>#wooboxproductcategory>.vc_element-icon {
	background-position: 0 0;
	background-image: url(../images/backend/icon.png);
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-ms-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
}

.wp-admin .vc_ui-button.vc_ui-button-action, .wp-admin .vc_ui-button.vc_ui-button-action:focus, .wp-admin .vc_ui-button.vc_ui-button-action:hover {
	box-shadow: none;
	background: #19beec;
	border-color: #19beec;
}

.admin-color-fresh .redux-container-switch .cb-disable.selected, .wp-customizer .redux-container-switch .cb-disable.selected {
	background-color: #19beec !important;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #19beec), color-stop(100%, #19beec)) !important;
	border-color: #19beec !important;
}

.redux-main .input-prepend .add-on {
	border-radius: 0;
}

.redux-main .select2-container .select2-choice {
	height: 43px;
	line-height: 43px;
	border-radius: 0;
	background-image: none;
}

.redux-main .input-append .add-on, .redux-main .input-prepend .add-on {
	text-shadow: none;
	background: #2b2b2b;
	color: var(--white-color);
}

.admin-color-fresh .select2-results .select2-highlighted, .wp-customizer .select2-results .select2-highlighted {
	background: #19beec;
}

.select2-drop.select2-drop-above .select2-search input {
	box-shadow: none;
}

.select2-drop.select2-drop-above .select2-search input:focus {
	box-shadow: none;
	border-color: #19beec;
}

.select2-container .select2-choice .select2-arrow b {
	background-color: #2b2b2b;
	background-position: 0 10px;
}

.wp-picker-container .wp-color-result.button {
	height: 45px;
}

.wp-color-result-text {
	background: #2b2b2b;
	border-radius: 0;
	border-left: 1px solid #2b2b2b;
	color: var(--white-color);
	display: block;
	line-height: 43px;
	padding: 0 15px;
}

.redux-main .redux-typography-container .select_wrapper {
	margin: 0 10px 30px 0;
}

.redux-main .redux-typography-container .input_wrapper input.mini {
	height: 50px;
}

.redux-main .description {
	margin-top: 30px;
}

/* ----------------------------------------
                Admin Custom css
 -----------------------------------------*/

.qloud-notice-main-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.qloud-notice-logo-push {
	padding: 1.25em 1.25em 0 .625em
}

.qloud-notice-message h3 {
	margin-bottom: .625em;
	margin-top: 1em !important
}

.qloud-notice-message {
	padding-bottom: 1.25em
}

.qloud-notice-action {
	margin-top: .9375em
}

@media (max-width: 400px) {
	.qloud-notice-main-box {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
}

.redux-container .form-table th {
	padding: 1.25em .625em 1.25em 0 !important
}

.redux-container .form-table td {
	margin-bottom: .5625em !important;
	padding: .9375em .625em !important
}

.redux-main .redux-field-container {
	padding: 1.25em 0 !important
}


/* Magnific Popup CSS */
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.8;
}

.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 8px;
	box-sizing: border-box;
}

.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	color: #CCC;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 1044;
}

.mfp-preloader a {
	color: #CCC;
}

.mfp-preloader a:hover {
	color: var(--white-color);
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	padding: 0 0 18px 10px;
	color: var(--white-color);
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
	opacity: 1;
}

.mfp-close:active {
	top: 1px;
}

.mfp-close-btn-in .mfp-close {
	color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	color: var(--white-color);
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%;
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #CCC;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
	margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
	opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: medium inset transparent;
}

.mfp-arrow:after {
	border-top-width: 13px;
	border-bottom-width: 13px;
	top: 8px;
}

.mfp-arrow:before {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: 0.7;
}

.mfp-arrow-left {
	left: 0;
}

.mfp-arrow-left:after {
	border-right: 17px solid var(--white-color);
	margin-left: 31px;
}

.mfp-arrow-left:before {
	margin-left: 25px;
	border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
	right: 0;
}

.mfp-arrow-right:after {
	border-left: 17px solid var(--white-color);
	margin-left: 39px;
}

.mfp-arrow-right:before {
	border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
	top: -40px;
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #000;
}

/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
	line-height: 0;
}

.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #444;
}

.mfp-figure small {
	color: #BDBDBD;
	display: block;
	font-size: 12px;
	line-height: 14px;
}

.mfp-figure figure {
	margin: 0;
}

.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #F3F3F3;
	word-wrap: break-word;
	padding-right: 36px;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {

	/**
		 * Remove all paddings around the image on small screen
		 */
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}

	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}

	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}

	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}

	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		box-sizing: border-box;
		margin: 0;
		padding: 3px 5px;
		position: fixed;
		top: auto;
	}

	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}

	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}

	.mfp-img-mobile .mfp-close {
		background: rgba(0, 0, 0, 0.6);
		height: 35px;
		line-height: 35px;
		padding: 0;
		position: fixed;
		right: 0;
		text-align: center;
		top: 0;
		width: 35px;
	}
}

@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}

	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}

	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}

	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}


/*---------------------------------------------------------------------
                             Back to Top
-----------------------------------------------------------------------*/
#back-to-top .top {
	margin: 0;
	color: var(--white-color);
	background: var(--secondary-color);
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 999;
	font-size: 26px;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	border-radius: 10px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

#back-to-top .top:hover {
	background: var(--white-color);
	color: var(--secondary-color);
	-webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

/*---------------------------------------------------------------------
                              Loader
-----------------------------------------------------------------------*/
#loading {
	align-items: center;
	background: var(--white-color);
	bottom: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
}

#loading img {
	width: 200px;
}

/*---------------------------------------------------------------------
							Box-layout
---------------------------------------------------------------------*/
body.boxed_layout {
	padding: 30px 0;
}

body.boxed_layout #page {
	-moz-box-shadow: 0 0 15px rgba(0, 0, 0, .06);
	-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, .06);
	box-shadow: 0 0 15px rgba(0, 0, 0, .06);
}

body.boxed_layout #page {
	margin: 0 auto;
	max-width: 1240px;
	overflow: hidden;
	position: relative;
}

body.boxed_layout.admin-bar header {
	top: 0;
}

body.full_width_layout #page {
	margin: 0 auto !important;
	max-width: 100% !important;
	width: 100% !important;
}

/*---------------------------------------------------------------------
							Box-Shadow
---------------------------------------------------------------------*/
.iq-box-shadow {
	-moz-box-shadow: var(--global-box-shadow);
	-webkit-box-shadow: var(--global-box-shadow);
	box-shadow: var(--global-box-shadow);
}

.iq-img-shadow {
	-moz-box-shadow: var(--global-box-shadow) !important;
	-webkit-box-shadow: var(--global-box-shadow) !important;
	box-shadow: var(--global-box-shadow) !important;
}

/*---------------------------------------------------------------------
                            OWL Carousel
-----------------------------------------------------------------------*/
.owl-carousel .owl-nav.disabled {
	display: none;
}

.owl-carousel .owl-nav {
	cursor: pointer;
	display: block;
	left: 0;
	position: absolute;
	text-indent: inherit;
	top: 50%;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100%;
	z-index: 999;
}

.owl-carousel .owl-nav .owl-prev {
	background: none;
	border: none;
	cursor: pointer;
	display: block;
	left: -8%;
	outline: none;
	position: absolute;
	text-align: center;
	text-indent: inherit;
	top: -25px;
	-moz-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
	-ms-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
	-o-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
	-webkit-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
	transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
	width: auto;
}

.owl-carousel .owl-nav .owl-next {
	background: none;
	border: none;
	cursor: pointer;
	display: block;
	outline: none;
	position: absolute;
	right: -8%;
	text-align: center;
	text-indent: inherit;
	top: -25px;
	-moz-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
	-ms-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
	-o-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
	-webkit-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
	transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
	width: auto;
}

.owl-carousel .owl-nav i {
	background: var(--title-color);
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	color: var(--white-color);
	display: inline-block;
	font-size: 24px;
	font-weight: 600;
	height: 45px;
	line-height: 45px;
	padding-left: 0;
	text-align: center;
	-moz-transition: all 0.5s ease-out 0s;
	-ms-transition: all 0.5s ease-out 0s;
	-o-transition: all 0.5s ease-out 0s;
	-webkit-transition: all 0.5s ease-out 0s;
	transition: all 0.5s ease-out 0s;
	width: 45px;
}

.owl-carousel .owl-nav i:hover {
	background: var(--primary-color);
	color: var(--white-color);
}

body.boxed_layout .owl-carousel .owl-nav .owl-prev {
	left: -10px;
}

body.boxed_layout .owl-carousel .owl-nav .owl-next {
	right: -10px;
}

.iq-slider-with-text .slider-nav .slick-arrow.slick-prev::before,
.iq-slider-with-text .slider-nav .slick-arrow.slick-next::before {
	color: var(--secondary-color);
}

/* iconic slider outline */
.iq-slider-with-text .slider-for .slider-text {
	outline: none;
}

/* Dots */
.owl-carousel .owl-dots.disabled {
	display: none;
}

.owl-carousel .owl-dots {
	cursor: pointer;
	display: inline-block;
	margin-top: 15px;
	position: relative;
	text-align: center;
	text-indent: inherit;
	width: 100%;
}

.owl-carousel .owl-dots .owl-dot {
	background: var(--primary-color);
	border: 2px solid var(--primary-color);
	border-radius: 90px;
	box-shadow: none;
	cursor: pointer;
	display: inline-block;
	height: 10px;
	margin: 0 5px;
	outline: none;
	padding: 0;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	vertical-align: middle;
	width: 10px;
}

.owl-carousel .owl-dots .owl-dot span {
	display: none;
}

.owl-carousel .owl-dots .owl-dot:hover {
	background: var(--white-color);
	border: 2px solid var(--primary-color);
	height: 15px;
	width: 15px;
}

.owl-carousel .owl-dots .owl-dot.active {
	background: var(--white-color);
	border: 2px solid var(--primary-color);
	height: 15px;
	width: 15px;
}

/*---------------------------------------------------------------------
                         404 Page
-----------------------------------------------------------------------*/
.error-404 {
	text-align: center;
}

.error-404 .big-text {
	color: var(--title-color);
	font-family: 'Poppins', sans-serif;
	font-size: 240px;
	font-weight: 700;
	line-height: 280px;
}

.error-404 h4 {
	margin: 0 0 15px;
}

.error-404 .button {
	margin-top: 30px
}

.error-404 .search-form {
	display: inline-block;
	margin-top: 30px;
	width: 285px
}


/*======================================
author-widget
============================================*/
.iq-author-widget .author-img {
	text-align: center;
	margin-bottom: 40px;
}

.iq-author-widget .author-img img {
	height: 150px;
	width: 150px;
	padding: 5px;
	border: 3px solid var(--primary-color);
	border-radius: 50%;
	-webkit-border-radius: 50%;
	object-fit: cover;
}

.iq-author-widget .author-deatail {
	text-align: center;
}

.iq-author-widget .author-deatail .author-contain {
	margin-bottom: 20px;
}

.iq-author-widget .author-designation {
	margin-top: 15px;
}

.iq-author-widget .author-designation .designation {
	color: var(--title-color);
}

.iq-author-social ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.iq-author-social .share-post {
	font-size: var(--font-size-body);
	font-weight: var(--font-weight-bold);
	color: var(--title-color);
	margin-bottom: 15px;
}

.iq-author-social ul li,
.iq-widget-author .iq-author-social ul li {
	display: inline-block;
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
	margin-right: 5px;
}

.iq-author-social ul li:last-child,
.iq-widget-author .iq-author-social ul li:last-child {
	margin-right: 0;
}

.iq-author-social ul li a,
.iq-widget-author .iq-author-social ul li a {
	height: 32px;
	width: 32px;
	line-height: 32px;
	display: inline-block;
	text-align: center;
	padding: 0;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	color: var(--white-color);
}

.iq-author-social ul li a.facebook {
	background: var(--primary-color);
}

.iq-author-social ul li a.twitter {
	background: var(--color-theme-cyan);
}

.iq-author-social ul li a.linkedin {
	background: var(--color-theme-blue);
}

.iq-author-social ul li a.pinterest {
	background: var(--color-theme-red);
}

.iq-author-social ul li a:hover {
	background: var(--white-color);
	color: var(--primary-color);
}

.iq-widget-author .iq-author-social ul li a::before {
	content: none;
}

/*============================
author
=====================================*/
.author-info {
	padding: 30px;
	background: var(--global-body-bgcolor);
	border-radius: var(--border-radius-ten);
	-webkit-border-radius: var(--border-radius-ten);
	display: flex;
	margin-bottom: 70px;
}

.author-info .author-img {
	height: 120px;
	min-width: 120px;
	width: 120px;
}

.author-info .author-img img {
	height: 100%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.author-info .author-details {
	margin-left: 30px;
}

.author-info .author-details .author-content p {
	margin-top: 5px;
	margin-bottom: 0;
	color: var(--primary-color);
	text-transform: uppercase;
	font-size: var(--font-size-normal);
	font-weight: var(--font-weight-bold);
	letter-spacing: var(--letter-spacing-one);
}

.author-info .author-details .top-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.author-info .author-details .description {
	margin-top: 25px;
	margin-bottom: 0;
}

.author-info .iq-author-social ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.author-info .iq-author-social ul li {
	display: inline-block;
	margin-right: 15px;
}

.author-info .iq-author-social ul li:last-child {
	margin-right: 0;
}

.author-info .iq-author-social ul li a {
	font-size: var(--font-size-normal);
	text-transform: uppercase;
	color: var(--tertiry-color);
	font-weight: var(--font-weight-medium);
	height: auto;
	width: auto;
	background: transparent;
}

.author-info .iq-author-social ul li a:hover {
	background: transparent;
	color: var(--primary-color);
}

/*---------------------------------------------------------------------
                             CONTACT US
-----------------------------------------------------------------------*/
.wpcf7-form input {
	margin-bottom: 30px;
}

.wpcf7-form textarea {
	line-height: 1.5;
	margin-bottom: 30px;
}

.wpcf7-form input[type=submit] {
	margin-bottom: 0;
}

.wpcf7-form label {
	width: 100%;
}

.contact-us iframe {
	display: inline-block;
	float: left;
	height: 450px;
	width: 100%;
}

input.wpcf7-not-valid {
	border-color: var(--color-theme-red);
}

span.wpcf7-not-valid-tip {
	margin-bottom: 15px;
}


/*--------------------------------------------------------------
                      Subscribe Now
--------------------------------------------------------------*/
.footer-subscribe {
	background: var(--title-color);
	margin-bottom: 75px;
	padding: 60px 0;
}

.footer-subscribe .vc_custom_heading {
	color: var(--white-color);
}

.footer-subscribe p {
	margin-bottom: 0;
}

.footer-subscribe .title {
	color: var(--white-color);
}


/*--------------------------------------------------------------
                     Contact Form
--------------------------------------------------------------*/
#wpcf7-f507-p385-o1 input,
#wpcf7-f507-p385-o1 textarea {
	background: transparent;
	border: 1px solid var(--white-color);
	color: var(--white-color);
}


#wpcf7-f507-p385-o1 input[type=submit] {
	background: var(--secondary-color);
	border-color: var(--secondary-color);
}

#wpcf7-f507-p385-o1 input[type=submit]:hover,
#wpcf7-f507-p385-o1 input[type=submit]:focus {
	background: var(--white-color);
	border-color: var(--primary-color);
	color: var(--primary-color);
}


#wpcf7-f507-p385-o1 input::-webkit-input-placeholder {
	color: var(--white-color);
}

#wpcf7-f507-p385-o1 input::-moz-placeholder {
	color: var(--white-color);
}

#wpcf7-f507-p385-o1 input:-ms-input-placeholder {
	color: var(--white-color);
}

#wpcf7-f507-p385-o1 textarea::-webkit-input-placeholder {
	color: var(--white-color);
}

#wpcf7-f507-p385-o1 textarea::-moz-placeholder {
	color: var(--white-color);
}

#wpcf7-f507-p385-o1 textarea:-ms-input-placeholder {
	color: var(--white-color);
}

@keyframes shadow-pulse-dots {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 123, 252, 0.2);
	}

	100% {
		box-shadow: 0 0 0 15px rgba(0, 123, 252, 0);
	}
}


/* Recent Post*/
.iq-widget-menu.widget ul li a::before {
	content: none;
}

.iq-post {
	margin: 0;
	padding: 0;
}

.iq-post li {
	list-style: none;
}

.iq-widget-menu.widget ul li {
	padding-bottom: 0;
	margin-bottom: 25px;
	border-bottom: none;
}

.iq-post li .post-img {
	display: flex;
	align-items: center;
}

.iq-post .post-img .post-img-holder a {
	display: inline-block;
	height: 70px;
	width: 70px;
	border-radius: var(--border-radius-box);
	padding: 0;
}

.iq-post .post-img .post-img-holder a img {
	width: 100%;
	height: 100%;
}

.iq-post li .post-blog {
	margin-left: 25px;
}

.iq-post .post-blog a {
	padding: 0;
}

.iq-post .post-blog a .iq-post-title {
	font-size: var(--font-size-h6);
	line-height: var(--font-line-height-h6);
}

.iq-post li .post-blog a:hover {
	text-decoration: none;
}

.iq-post .post-blog a:hover .iq-post-title {
	color: var(--primary-color);
}

.iq-post li .post-blog ul li a {
	color: var(--tertiry-color);
	font-size: var(--font-size-xs);
	padding: 0;
	text-transform: uppercase;
	font-weight: var(--font-weight-semi-bold);
}

.iq-post li .post-blog ul li a.date-widget {
	letter-spacing: var(--letter-spacing-one);
}

.iq-post li .post-blog ul li a:hover {
	color: var(--primary-color);
}

.iq-post li .post-blog a:before {
	display: none;
}

.iq-domain-search .iq-wp-whmcs-form-input-box form {
	text-align: center;
	position: relative;
	margin: 0;
	border-radius: 10px;
}

.iq-domain-search .iq-wp-whmcs-form-input-box input[type="text"] {
	background: var(--global-body-bgcolor);
	border: none;
	border-radius: var(--border-radius-box);
	float: inherit;
	height: 80px;
	margin: 0 auto;
	padding: 0 20px;
	width: 100%;
}

.domain-search .iq-domain-search .iq-wp-whmcs-form-input-box input[type="text"] {
	background: var(--global-body-bgcolor) !important;
	box-shadow: none !important;
}

.iq-domain-search .iq-wp-whmcs-form-input-box input[type="submit"] {
	float: inherit;
	background: var(--primary-color) none repeat scroll 0 0;
	position: absolute;
	right: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	height: 80px;
	border-radius: 0 5px 5px 0;
}

.iq-domain-search .iq-wp-whmcs-form-input-box input[type=submit]:hover,
.iq-domain-search .iq-wp-whmcs-form-input-box input[type=submit]:focus {
	background: var(--secondary-color);
	color: var(--white-color);
}

/*Host table css*/
.iq-wp-whmcs-domain table th {
	border: none;
	padding: 30px;
	font-family: var(--highlight-font-family);
	font-size: var(--font-size-h5);
	line-height: var(--font-line-height-h5);
	color: var(--white);
}

.iq-wp-whmcs-domain table tr {
	background: #103dbe;
	text-align: center;
}

.iq-table thead tr {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.iq-wp-whmcs-domain table th.iq-row {
	background: var(--white) !important;
	color: var(--title-color);
}

.iq-wp-whmcs-domain table th.iq-heading {
	color: var(--white);
	background: none;
}

.iq-wp-whmcs-domain table tr th.iq-heading:first-child {
	border-top-left-radius: 5px;

}

.iq-wp-whmcs-domain table tr th.iq-heading:last-child {

	border-top-right-radius: var(--border-radius-box);
}

.iq-wp-whmcs-domain table tbody {
	border: 1px solid var(--border-color-light);
	border-bottom-left-radius: var(--border-radius-box);
	border-bottom-right-radius: var(--border-radius-box);
}


.iq-wp-whmcs-domain table tbody tr {
	border: 5px solid var(--global-body-bgcolor);
	border-left: none;
	border-right: none;
	border-top: none;
	border-bottom: none;
}

.iq-wp-whmcs-domain table tbody tr:nth-child(2n+0) td {
	background: var(--global-body-bgcolor);
}

.iq-wp-whmcs-domain table {
	border: none;
}

.iq-wp-whmcs-domain table td {
	border: 1px solid var(--global-body-bgcolor);
	padding: 32px;
	font-size: var(--font-size-body);
	background: var(--white-color);
	border-width: 0 1px 0 0;
	text-align: center;
}

.iq-wp-whmcs-domain table td:last-child {
	border-width: 0;
}

/*====================
responsive
==========================*/
@media (max-width: 767px) {
	.author-info {
		display: block;
	}

	.author-info .author-details {
		margin-left: 0;
		margin-top: 30px;
	}

	.author-info .author-details .top-info {
		display: block;
	}

	.author-info .iq-author-social {
		margin-top: 15px;
	}
}

@media (max-width: 479px) {
	.iq-post li .post-img {
		display: block;
	}

	.iq-post li .post-blog {
		margin-left: 0;
		margin-top: 15px;
	}

	.iq-author-widget .author-designation .designation {
		display: block;
	}
}

/* whmcs bridge */

#bridge section#header {
	padding-top: 80px;
	padding-bottom: 0;
}

#bridge section#header .container {
	display: none;
}

#bridge section#main-menu {
	background: transparent;
}

#bridge #main-menu {
	position: relative;
	z-index: 10;
}

#bridge ul.top-nav>li.primary-action>a.btn {
	background: var(--secondary-color);
}

#bridge nav#nav {
	background: var(--secondary-color);
	padding: 0 15px;
}

#bridge #main-menu .navbar-nav {
	flex-direction: unset;
	display: inline-block;
}

#bridge .navbar-nav .dropdown-menu {
	position: absolute;
}

#bridge .navbar-main .navbar-nav>.active>a,
#bridge .navbar-main .navbar-nav>.active>a:focus,
#bridge .navbar-main .navbar-nav>.active>a:hover,
#bridge .navbar-main .navbar-nav>.open>a,
#bridge .navbar-main .navbar-nav>.open>a:focus,
#bridge .navbar-main .navbar-nav>.open>a:hover,
#bridge .navbar-main .navbar-nav>li>a:focus,
#bridge .navbar-main .navbar-nav>li>a:hover {
	background: var(--primary-color);
}

#bridge ul.nav li.dropdown:hover ul.dropdown-menu {
	display: block;
}

#bridge .dropdown-toggle::after {
	display: none;
}

#bridge section#home-banner .input-group-btn input {
	float: none;
}

#bridge section#home-banner {
	background-color: var(--primary-color);
	color: var(--white-color);
	padding: 120px 0 80px;
	position: relative;
}

#bridge section#home-banner .btn {
	font-size: 16px;
}

#bridge section#home-banner .btn.search {
	background-color: var(--secondary-color);
}

#bridge section#home-banner .btn.transfer {
	background-color: #777;
}

#bridge section#home-banner h2 {
	margin-bottom: 32px;
	text-transform: capitalize;
	font-weight: 600;
	font-size: 30px;
}

#bridge .home-shortcuts {
	width: 100%;
	float: left;
	padding: 120px 0;
	border-radius: 10px;
	background: var(--white-color);
}

#bridge .home-shortcuts .col-md-4.hidden-sm.hidden-xs.text-center {
	max-width: 100%;
	flex: 0 0 100%;
}

#bridge .home-shortcuts .col-md-4.hidden-sm.hidden-xs.text-center p {
	font-size: 30px;
	line-height: 30px;
	font-weight: 600;
	margin-bottom: 35px;
	text-transform: capitalize;
	color: var(--primary-color);
}

#bridge .home-shortcuts .col-sm-12.col-md-8 {
	max-width: 100%;
	flex: 0 0 100%;
}

#bridge .home-shortcuts li {
	width: 25%;
	padding: 25px 15px;
	border: none;
	position: relative;
	z-index: 9;
}

#bridge .home-shortcuts li a {
	position: relative;
	padding: 60px 030px 060px 030px;
	background: var(--white-color);
	box-shadow: 0 2px 43.65px 1.35px rgba(0, 0, 0, 0.06);
}

#bridge .home-shortcuts li a:before {
	position: absolute;
	content: "";
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: var(--primary-color);
	transform: rotate(0deg);
	transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

#bridge .home-shortcuts li:hover a:before {
	opacity: 1;
	transform: rotate(12deg);
}

#bridge .home-shortcuts li>a>i {
	font-size: 30px;
	color: var(--primary-color);
	margin-bottom: 15px;
}

#bridge .home-shortcuts li p {
	font-size: 16px;
	line-height: 16px;
	font-weight: 600;
	text-transform: capitalize;
	color: var(--primary-color);
	transition: .5s ease-in-out;
}

#bridge .home-shortcuts li span {
	display: none;
}

#bridge #main-body .main-content {
	border: 2px solid var(--border-color-light);
	border-radius: 10px;
}

#bridge .main-content .announcement-single .label {
	border-radius: 5px;
}

#bridge #main-body .main-content h2 {
	color: var(--primary-color);
	font-weight: 800;
}

#bridge #main-body .main-content blockquote {
	border: none;
	padding: 0;
	margin-bottom: 0;
}

#bridge .announcement-single h3 a {
	color: var(--primary-color);
	font-weight: 600;
}

#bridge .announcement-single h3 a:hover {
	text-decoration: none;
}

#bridge #footer {
	display: none;
}


div.wpcf7-response-output {
	display: none;
}

.wpcf7-form .cfield .wpcf7-not-valid {
	border: 1px solid var(--color-theme-red);
}

.site-content {
	display: inline-block;
	width: 100%;
	float: left;
}

.vc_parallax {
	overflow: hidden !important;
}


/*---------------------------------------------------------------------
                          Clearing floats
-----------------------------------------------------------------------*/

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

/*---------------------------------------------------------------------
                          Clearing floats
-----------------------------------------------------------------------*/
.clear:after,
.wrapper:after,
.format-status .entry-header:after {
	clear: both;
}

.clear:before,
.clear:after,
.wrapper:before,
.wrapper:after,
.format-status .entry-header:before,
.format-status .entry-header:after {
	display: table;
	content: "";
}

@media (max-width: 767px) {
	.qloud-size a.rs-layer {
		padding: 0 !important;
		line-height: inherit !important;
		text-align: center;
		height: auto !important;
		font-size: 13px !important;
	}
}

/*

Template: Qloud  - Qloud Landing Page WordPress Theme
Author: iqonicthemes.in
Version: 2.6
Design and Developed by: iqonicthemes.in

*/
@media only screen and (min-width: 1200px) {
	body.boxed_layout {
		padding: 30px;
	}
}

@media (max-width: 1199px) {
	table td, table th {
		padding: 5px;
		text-align: center
	}

	.iq-tabs .nav-pills .nav-link {
		padding-left: 0;
		padding-right: 0;
	}

	.blog-detail-wrap {
		padding: 0 15px;
	}
}

@media (max-width: 991px) {
	.site-main .sf-content>.vc_row.wpb_row.vc_row-fluid {
		padding: 70px 0;
	}

	.shop_list {
		display: none;
	}

	.iq-height {
		position: fixed;
	}

	.widget_calendar .calendar_wrap table th, .widget_calendar .calendar_wrap table td {
		padding: 5px;
	}

	.widget_calendar .calendar_wrap table th, .widget_calendar .calendar_wrap table td {
		padding: 2px;
	}

	.owl-carousel.blog-carousel {
		margin: 0;
	}

	.iq-popup-video, .iq-popup-video .position-relative {
		min-height: 200px;
	}
}

@media (max-width: 767px) {
	.page-numbers li .prev.page-numbers, .page-numbers li .next.page-numbers {
		display: none;
	}

	.error-404 .big-text {
		font-size: 200px;
	}
}

@media (max-width: 479px) {
	.error-404 .big-text {
		font-size: 150px;
		line-height: 160px;
	}

	.iq-wp-whmcs-form-input-box form {
		padding: 30px 10px;
	}

	.iq-wp-whmcs-form-input-box input[type="text"] {
		padding: 0 5px;
	}

	.iq-domain-search .iq-wp-whmcs-form-input-box input[type=submit],
	.iq-wp-whmcs-form-input-box input[type=submit] {
		position: static;
		width: 100%;
		margin-top: 16px;
		border-radius: var(--border-radius-box);
		-webkit-border-radius: var(--border-radius-box);
		-moz-border-radius: var(--border-radius-box);
	}
}



/* rs slider domain search  */
.iq-domain-search.rs-domain-search .iq-wp-whmcs-form-input-box input[type=text] {
	border-radius: 10px;
	margin-bottom: 20px;
	background: var(--global-body-bgcolor);
	box-shadow: none;
	text-align: center;
	height: 60px;
}

.iq-domain-search.rs-domain-search input[type="email"]::placeholder {

	/* Firefox, Chrome, Opera */
	text-align: center;
}

.iq-domain-search.rs-domain-search input[type="text"]::placeholder {

	/* Firefox, Chrome, Opera */
	text-align: center;
}

.iq-domain-search.rs-domain-search input[type="tel"]::placeholder {

	/* Firefox, Chrome, Opera */
	text-align: left;
}

.iq-domain-search.rs-domain-search input[type="email"]:-ms-input-placeholder {

	/* Internet Explorer 10-11 */
	text-align: center;
}

.iq-domain-search.rs-domain-search input[type="email"]::-ms-input-placeholder {

	/* Microsoft Edge */
	text-align: center;
}

.iq-domain-search.rs-domain-search .iq-wp-whmcs-form-input-box input[type=submit] {
	float: inherit;
	position: absolute;
	right: 0;
	left: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	height: 60px;
	border-radius: 5px 5px;
	width: 100%;
}

.rs-domain-search .iq-wp-whmcs-form-input-box input[type=submit] {
	background-color: var(--primary-color);
}

.rs-domain-search .iq-wp-whmcs-form-input-box input[type=submit]:hover {
	background-color: var(--secondary-color);
}

/* span count */
.widget.widget_archive ul li span.archiveCount, .widget_categories ul li span {
	float: right;
	color: var(--tertiry-color);
	width: 30px;
	height: 30px;
	text-align: center;
	font-size: var(--font-size-normal);
}

/* revolution slider */
.textgyreadventor {
	font-family: var(--highlight-font-family) !important;
}

.rs_slider-11 .mc4wp-form .mc4wp-form-fields {
	display: flex;
}

.rs_slider-11 .mc4wp-form .mc4wp-form-fields input[type="email"] {
	width: 60%;
	margin-right: 30px;
}

/* home 2 rs slider  */
.rs-domain-search-home-2 .wpWhmcsSearchDomainName {
	width: 100%;
	margin-right: 0;
	height: 66px !important;
	background: var(--white-color) !important;
}

.rs-domain-search-home-2 input[type="submit"] {
	position: absolute;
	right: 0;
	border-radius: 0 5px 5px 0;
	top: 0;
	height: 66px !important;
	background-color: var(--primary-color) !important;
	letter-spacing: var(--letter-spacing-three);
}

.rs-domain-search-home-2 input[type="submit"]:hover {
	background-color: var(--secondary-color) !important;
}

@media (max-width: 479px) {
	.rs_slider-11 .mc4wp-form .mc4wp-form-fields input[type=email] {
		width: 100%;
		margin-right: 0;
	}

	.rs_slider-11 .mc4wp-form .mc4wp-form-fields {
		display: flex;
		flex-wrap: wrap;
	}

	.rs_slider-11 .mc4wp-form-fields input[type=submit] {
		position: unset;
		width: 100%;
	}

	.rs-domain-search-home-2 input[type="submit"] {
		top: 30px !important;
	}
}

@media (max-width: 380px) {
	.rs-domain-search-home-2 input[type="submit"] {
		position: unset !important;
		width: 100%;
		border-radius: var(--border-radius-box) !important;
		margin-top: 15px;
	}
}


/* Contact us form   */
.wpcf7-form p {
	margin-top: 0;
}

.wpcf7-text.fullname-form, .email.Address-form {
	margin-bottom: 20px;
	border-radius: var(--border-radius-box) !important;
}

.wpcf7-form-control-wrap .text-area-group {
	margin-bottom: 35px;
	border-radius: var(--border-radius-box) !important;
}

/*--------------------
contact-form-2
-----------------------*/
.contact-form-new input,
.contact-form-new textarea {
	width: 100%;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid var(--input-border-light);
	border-radius: 0;
	color: var(--tertiry-color);
	padding: 0;
	margin-bottom: 40px;
}

.comments-area input,
.comments-area textarea {
	padding: 0;
	border: none;
	border-bottom: 1px solid var(--input-border-light);
	border-radius: 0;
	color: var(--tertiry-color);
	background-color: transparent;
}

.comment-form {
	position: relative;
}

.contact-form-new input::placeholder,
.contact-form-new textarea::placeholder,
.comments-area input::placeholder,
.comments-area textarea::placeholder {
	color: transparent;
}

.contact-form-new input:not(:placeholder-shown),
.contact-form-new textarea:not(:placeholder-shown) {
	background: transparent;
	border-color: var(--input-border-light);
}

.contact-form-new input:focus,
.contact-form-new textarea:focus,
.contact-form-new input:focus-within,
.contact-form-new input:focus-visible,
.comments-area input:focus,
.comments-area textarea:focus {
	border: none;
	border-bottom: 1px solid var(--primary-color);
	background: transparent;
	border-radius: 0;
}

.contact-form-new .wpcf7-form-control-wrap {
	display: block;
}

.contact-form-new label,
.comments-area label {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	transform-origin: 0 0;
	color: var(--tertiry-color);
	font-size: var(--font-size-body);
	font-weight: var(--font-weight-regular);
	height: auto;
	padding: 0;
	border: none;
	transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
}

.comments-area .comment-form-cookies-consent label {
	position: static;
	padding: 0;
	border: none;
}

.contact-form-new input:focus~label,
.contact-form-new input:not(:placeholder-shown)~label,
.comments-area input:focus~label,
.comments-area input:not(:placeholder-shown)~label {
	opacity: 1;
	-webkit-transform: translateY(-10px) translateX(0);
	transform: translateY(-10px) translateX(0);
	padding: 0;
	background: transparent;
	border: none;
	line-height: 1.1em;
	color: var(--primary-color);
}

.contact-form-new textarea:focus~label,
.contact-form-new textarea:not(:placeholder-shown)~label,
.comments-area textarea:focus~label,
.comments-area textarea:not(:placeholder-shown)~label {
	-webkit-transform: translateY(-20px) translateX(0);
	transform: translateY(-20px) translateX(0);
	color: var(--primary-color);
}

.comments-area .comment-form-cookies-consent label,
.comments-area .comment-form-cookies-consent input:focus~label,
.comments-area .comment-form-cookies-consent input:not(:placeholder-shown)~label {
	transform: none;
	-webkit-transform: none;
	color: var(--tertiry-color);
}

.contact-form-new textarea,
.comments-area textarea {
	min-height: 6.25em;
	padding: 0;
}

.contact-form-new .form-button {
	margin-top: 1em;
}

.contact-form-new .css_prefix-checkbox .wpcf7-list-item {
	margin: 0;
}

.contact-form-new input[type='checkbox'] {
	width: auto;
	display: inline-block;
	margin: 0 10px 0 0;
	height: 27px;
}


/* rs popup style 2 */

.rs-popup-video.style-two,
.rs-popup-video.style-two .position-relative {
	min-height: auto;
}

.rs-popup-video.style-two .rs-video-img {
	display: inline-flex;
	align-items: center;
}

.rs-popup-video.style-two .rs-video-icon {
	position: static;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

.rs-popup-video.style-two .rs-video {
	height: 50px;
	width: 50px;
	line-height: 50px;
	font-size: var(--font-size-body);
	z-index: 99;
	position: relative;
	background-color: var(--white-color);
	display: inline-block;
	border-radius: 50%;
	--webkit-border-radius: 50%;
}

.rs-popup-video.style-two .rs-waves {
	width: 10rem;
	height: 10rem;
	top: 50%;
	left: 50%;
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	opacity: 0.5;
}

.rs-popup-video.style-two .rs-waves .waves {
	height: 10rem;
	width: 10rem;
}

.rs-popup-video.style-two .video-text {
	margin-left: 30px;
	z-index: 3;
}

.rs-popup-video.style-two .video-text a {
	text-transform: capitalize;
	font-size: var(--font-size-h5);
	line-height: var(--font-line-height-h5);
	font-weight: var(--font-weight-bold);
	color: var(--tertiry-color);
	cursor: pointer;
}

.rs-popup-video.style-two .video-text a:hover {
	color: var(--primary-color);
}

.rs-waves {
	position: absolute;
	width: 10rem;
	height: 10rem;
	left: -80px;
	top: -80px;
	z-index: 2;
	float: right;
	opacity: 0.2;
}

.rs-waves .waves {
	position: absolute;
	width: 20rem;
	height: 20rem;
	background: var(--white-color);
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	border-radius: 320px;
	background-clip: padding-box;
	-webkit-animation: waves 3s ease-in-out infinite;
	animation: waves 3s ease-in-out infinite;
}

.rs-waves .wave-1 {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.rs-waves .wave-2 {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.rs-waves .wave-3 {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

@-webkit-keyframes waves {
	0% {
		-webkit-transform: scale(0.3, 0.3);
		transform: scale(0.3, 0.3);
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}

	50% {
		opacity: 0.9;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	}

	100% {
		-webkit-transform: scale(0.9, 0.9);
		transform: scale(0.9, 0.9);
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}
}

@keyframes waves {
	0% {
		-webkit-transform: scale(0.2, 0.2);
		transform: scale(0.2, 0.2);
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}

	50% {
		opacity: 0.9;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	}

	100% {
		-webkit-transform: scale(0.9, 0.9);
		transform: scale(0.9, 0.9);
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}
}

.rs-popup-video .rs-popup-video {
	color: var(--white-color);
}

/* rs button */
.rs-secondary-btn {
	background-color: var(--secondary-color) !important;
	color: var(--white-color);
}

.rs-secondary-btn:hover {
	background-color: var(--secondary-color);
	color: var(--white-color);
}

/* popup video style 3 */

.rs-popup-video.style-three .position-relative {
	min-height: auto;
}

.rs-popup-video.style-three .rs-video-img {
	display: inline-flex;
	align-items: center;
}

.rs-popup-video.style-three .rs-video-icon {
	position: static;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

.rs-popup-video.style-three .rs-video {
	height: 30px;
	width: 30px;
	line-height: 30px;
	font-size: var(--font-size-body);
	z-index: 99;
	position: relative;
	color: var(--white-color);
	background-color: var(--secondary-color);
	display: inline-block;
	border-radius: 50%;
	--webkit-border-radius: 50%;
}

.rs-popup-video.style-three .rs-waves {
	width: 5rem;
	height: 5rem;
	top: 50%;
	left: 50%;
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	opacity: 0.5;
}

.rs-popup-video.style-three .rs-waves .waves {
	height: 5rem;
	width: 5rem;
}

.rs-popup-video.style-three .video-text {
	margin-left: 30px;
	z-index: 3;
	color: var(--primary-color);
	cursor: pointer
}

.rs-popup-video.style-three .video-text a {
	text-transform: capitalize;
	font-size: var(--font-size-h5);
	line-height: var(--font-line-height-h5);
	font-weight: var(--font-weight-bold);
	color: var(--tertiry-color);
	cursor: pointer;
}

.rs-popup-video.style-three .video-text a:hover {
	color: var(--primary-color);
}

.rs-popup-video.style-three .rs-waves .waves {
	background: var(--secondary-color);
}

/* popup video style 4 */

.rs-popup-video.style-four .position-relative {
	min-height: auto;
}

.rs-popup-video.style-four .rs-video-img {
	display: inline-flex;
	align-items: center;
}

.rs-popup-video.style-four .rs-video-icon {
	position: static;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

.rs-popup-video.style-four .rs-video {
	height: 40px;
	width: 40px;
	line-height: 40px;
	font-size: var(--font-size-body);
	z-index: 99;
	position: relative;
	color: var(--white-color);
	background-color: var(--primary-color);
	display: inline-block;
	border-radius: 50%;
	--webkit-border-radius: 50%;
}

.rs-popup-video.style-four .rs-waves {
	width: 5rem;
	height: 5rem;
	top: 50%;
	left: 50%;
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	opacity: 0.5;
}

.rs-popup-video.style-four .rs-waves .waves {
	height: 5rem;
	width: 5rem;
}

.rs-popup-video.style-four .video-text {
	margin-left: 30px;
	z-index: 3;
	font-size: var(--font-size-h6);
	font-weight: var(--font-weight-bold);
	color: var(--title-color);

}

.rs-popup-video.style-four .video-text a {
	text-transform: capitalize;
	font-size: var(--font-size-h5);
	line-height: var(--font-line-height-h5);
	font-weight: var(--font-weight-bold);
	color: var(--tertiry-color);
	cursor: pointer;
}

.rs-popup-video.style-four .video-text a:hover {
	color: var(--primary-color);
}

.rs-popup-video.style-four .rs-waves .waves {
	background: var(--primary-color);
}