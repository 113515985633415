/*--------------------------   Search Box     ------------------------ */

header .search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(17 17 17 / 95%);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 31.25em;
  pointer-events: none;
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -o-opacity: 0;
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

header .search.search--open {
  pointer-events: auto;
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}

.btn--search-close {
  font-size: 1.5em;
  position: absolute;
  top: 1em;
  right: 1.25em;
  display: none;
  color: var(--white-color);
  background: transparent;
  display: block;
}

body.admin-bar .btn--search-close {
  top: 1.5em;
}

.btn--search-close:focus {
  outline: 0;
  box-shadow: none;
}

.search--open .search__form {
  margin: 5em 0;
  width: auto;
  min-width: 430px;
  position: relative;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

.search__form::after {
  content: '';
  position: absolute;
  top: auto;
  bottom: -0.625em;
  display: block;
  width: 100%;
  height: 0.063em;
  background-color: var(--white-color);
  -webkit-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  -o-animation-delay: 0.8s;
  animation-delay: 0.8s;
  visibility: hidden;
}

header .search--open .search__form::after {
  -webkit-animation-name: fadeInMove;
  animation-name: fadeInMove;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

header .search__input {
  font-family: inherit;
  background: none;
  border: none;
  height: auto;
  font-size: 2.813em;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.05em 0;
  color: var(--white-color);
  border-bottom: 0.125em solid;
}

header .search__input:focus {
  border: none;
  border-bottom: 0.125em solid;
}

header .search__input::-webkit-input-placeholder {
  opacity: 0.6;
  color: var(--white-color);
}

header .search__input::-moz-placeholder {
  opacity: 0.6;
  color: var(--white-color);
}

header .search__input:-ms-input-placeholder {
  opacity: 0.6;
  color: var(--white-color);
}

header .search-form input:focus,
header .search-form input[type=text]:focus,
header .search-form input[type=email]:focus,
header .search-form input[type=search]:focus,
header .search-form input[type=password]:focus {
  border: none !important;
}


.css_prefix-shop-btn-holder ul {
  margin: 0;
}

.search-box input[type="search"] {
  height: 2.5em;
  font-size: 1.125em;
  display: inline-block;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 1em 2.5em 1em 1em;
  width: 0px;
  position: absolute;
  top: -9px;
  right: 0;
  background: none;
  transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
  cursor: pointer;
  z-index: 11;
  margin: -0.625em 0 0 0;
}

.search-form .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  height: 3.75em;
  width: 3.75em;
  color: var(--white-color);
  cursor: pointer;
  font-size: var(--font-size-body);
  background: var(--primary-color);
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  border-radius: 0 0.188em 0.188em 0;
}

.search-box input[type="search"]:focus {
  width: 15.625em;
  z-index: 1;
  cursor: text;
  background: var(--white-color);
  z-index: 9;
  color: rgba(102, 102, 102, 0.6);
}

.search-box {
  position: relative;
}

.search-box .search-submit:hover {
  background: none;
}

.search-form .search-submit svg {
  font-size: 1.125em;
  color: var(--white-color);
}

button#btn-search-close svg {
  color: var(--white-color);
}

.search-form .search-submit {
  top: 10.125em;
}

.search-form .search-submit svg {
  font-size: 1.125em;
  color: var(--white-color);
}

.navbar ul.css_prefix-shop-btn-holder ul li a:hover {
  background: transparent;
}

.search-form .search-submit {
  background: transparent;
  line-height: normal;
}

.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search__input::-ms-clear {
  display: none;
}

form.search-form .form-row .has-content~label,
form.search-form .form-row input:focus~label,
form.search-form .form-row label {
  top: -5.625em;
}

.css_prefix-shop-btn-holder ul li svg {
  font-size: 1.25em;
  height: 1em;
  width: 1.25em;
}

.search-form input {
  border-radius: var(--border-radius);
  border: none;
  background: transparent;
  padding: 0;
}

form.search-form .form-row label {
  color: var(--white-color);
  text-align: left;
  font-size: 2.813em;
  top: -0.813em;
  margin-bottom: 0;
  left: 0;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 0px;
  position: absolute;
  pointer-events: none;
  left: 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.search-form input {
  padding-right: 3.125em;
  box-shadow: none;
  border: none;
  background: transparent;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.search-form {
  position: relative;
}

.search-form .form-search .search-submit {
  position: absolute;
  right: 0.063em;
  top: 0;
  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none !important;
  margin: 0;
}

header .search-form .form-search .search-submit:hover {
  background: transparent;
}

.search_count #btn-search, .search_count #btn-search svg {
  font-family: var(--highlight-font-family);
  color: var(--title-color);
  text-transform: uppercase;
  letter-spacing: 0.188em;
}

.search_count #btn-search:hover,
.search_count #btn-search:hover svg {
  color: var(--primary-color);
}

.css_prefix-header-right {
  display: flex;
  align-items: center;
}

/* layout search  */
.search_count .css_prefix-search, header .css_prefix-search {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background: rgb(17 17 17/95%);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 31.25em;
  pointer-events: none;
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -o-opacity: 0;
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
}


.css_prefix-search.search--open, .search_count .css_prefix-search.search--open {
  pointer-events: auto;
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}



/* search-colormode */

.search_style_light .search {
  background: var(--white-color);
  box-shadow: var(--global-box-shadow);
}

.search_style_light .search__form:after {
  background: var(--tertiry-color);
}

.search_style_light .search-form .search-submit svg {
  color: var(--tertiry-color);
}

.search_style_light input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.search_style_light button#btn-search-close svg {
  color: var(--title-color);
}

/* search animation */

.search-animate-left .search {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
}

.search-animate-left .search.search--open {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}

.search-animate-right .search {
  transform: translateX(-100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -ms-transform: translateX(100%);
}

.search-animate-right .search.search--open {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}

/* search default */
.search_wrap.search-form-default .search-form input {
  border-bottom: 0.063em solid var(--title-color) !important;
  border-radius: 0;
  height: 2.813em;
  line-height: 2.813em;
  font-size: 1.125em;
  padding-right: 1.875em;
}

.search_wrap .search-form input::placeholder {
  color: var(--tertiry-color);
}

.search_wrap.search-form-default .search-form .search-submit svg {
  font-size: 1.125em;
  color: var(--tertiry-color);
}

.search_wrap.search-form-default .search-form .form-search .search-submit {
  padding: 0;
  height: 1.875em;
  width: 1.875em;
  line-height: 3.125em;
}

.search-form-default .search-form input:focus,
.search-form-default .search-form input[type=email]:focus,
.search-form-default .search-form input[type=password]:focus,
.search-form-default .search-form input[type=search]:focus,
.search-form-default .search-form input[type=text]:focus {
  border-color: var(--primary-color) !important;
}

/*===========================
search
==================================*/
.widget.widget_search {
  padding: 0;
  background: transparent;
}

.widget .search-form input,
.wp-block-search .wp-block-search__input,
.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper .wp-block-search__input {
  padding-right: 5em;
  padding-left: 1em;
  background: var(--global-body-bgcolor);
  border: 1px solid transparent;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  height: 70px;
}

.wp-block-search.wp-block-search__button-outside .wp-block-search__inside-wrapper .wp-block-search__input,
footer .wp-block-search.wp-block-search__button-outside .wp-block-search__inside-wrapper .wp-block-search__input {
  padding-right: 1em;
}

footer .widget .search-form input,
footer .wp-block-search .wp-block-search__input,
footer .wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper .wp-block-search__input {
  background: var(--white-color);
}

.wp-block-search.wp-block-search__button-inside .wp-block-search__inside-wrapper {
  padding: 0;
  border: none;
}

.widget .search-form input:focus,
.wp-block-search .wp-block-search__input:focus,
.wp-block-search__button-inside.wp-block-search__icon-button.wp-block-search .wp-block-search__inside-wrapper .wp-block-search__input:focus {
  border: 1px solid var(--secondary-color)
}

.wp-block-search .wp-block-search__button,
.wp-block-search__button-inside.wp-block-search__icon-button.wp-block-search .wp-block-search__inside-wrapper .wp-block-search__button {
  position: absolute;
  background: var(--primary-color);
  line-height: 70px;
  width: 70px;
  height: 70px;
  right: 0px;
  top: 0px;
  cursor: pointer;
  padding: 0;
  font-size: var(--font-size-body);
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
}

.wp-block-search .wp-block-search__button:hover,
.wp-block-search__button-inside.wp-block-search__icon-button.wp-block-search .wp-block-search__inside-wrapper .wp-block-search__button:hover {
  background: var(--secondary-color);
}

.wp-block-search.wp-block-search__button-inside.wp-block-search__text-button .wp-block-search__inside-wrapper .wp-block-search__button {
  width: auto;
  height: 70px;
  line-height: 70px;
  border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  padding: 0 15px;
  top: 0;
  right: 0;
}

.wp-block-search.wp-block-search__button-outside.wp-block-search__icon-button .wp-block-search__inside-wrapper .wp-block-search__button {
  border-radius: var(--border-radius-box);
  -webkit-border-radius: var(--border-radius-box);
  position: static;
}

.wp-block-search.wp-block-search__button-outside.wp-block-search__text-button .wp-block-search__inside-wrapper .wp-block-search__button {
  height: 70px;
  line-height: 0;
  padding: 15px;
  width: auto;
  border-radius: var(--border-radius-box);
  -webkit-border-radius: var(--border-radius-box);
  position: static;
  overflow: inherit;
}

body.search header.page-header {
  position: static;
  background: transparent;
}

.css_prefix-error .search-form {
  display: inline-block;
  width: 40%;
  margin: 1em 0 1.563em;
}

.css_prefix-error .page-content>p {
  width: 50%;
  margin: 1em auto;
}

.css_prefix-error .search-form input {
  padding: 0 4.375em 0 1em;
  font-size: var(--font-size-body);
  position: relative;
  background: var(--global-body-lightcolor);
  border: 0.063em solid transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.css_prefix-error .search-form .search-submit {
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .css_prefix-error .search-form {
    width: 80%;
  }

  .css_prefix-error .page-content>p {
    width: 70%;
  }
}

@media (max-width: 479px) {
  .css_prefix-error .search-form {
    width: 90%;
  }

  .css_prefix-error .page-content>p {
    width: 90%;
  }
}


/* custom header search */
.search_wrap.search-form-default.search-animate-default .search-form input {
  border: 1px solid var(--global-body-bgcolor) !important;
  border-radius: var(--border-radius-box);
  outline: var(--primary-color);
  background: var(--global-body-bgcolor);
  padding: 0 0 0 60px;
  color: var(--tertiry-color);
  font-size: var(--font-weight-regular);
}

.search-form-default.search-animate-default .search-form .search-submit {
  background: none;
  color: var(--primary-color);
  padding: 10px 16px;
  height: unset;
  width: unset;
  line-height: unset;
  right: unset;
  left: 16px;
  border-radius: var(--border-radius-box);
}

.search-form-default.search-animate-default .search__form:after {
  content: unset !important;
}

/* search default with dropdown  */
.search_style_default .css_prefix-search.search--open .search__input {
  font-size: var(--font-size-h5);
  border-radius: var(--border-radius-box);
  color: var(--tertiry-color);
  padding-right: 4em;
  border: none;
}

.search_style_default .btn--search-close:hover {
  color: var(--primary-color);
}

.search_style_default .css_prefix-search.search--open .search-submit {
  background-color: var(--secondary-color) !important;
  right: 0;
}

.search_style_default .css_prefix-search.search--open .search-submit :hover {
  background-color: var(--primary-color) !important;
}

@media (max-width: 767px) {
  .search_style_default .css_prefix-search .search-form {
    padding: 0 16px;
    min-width: auto;
  }

  .search_style_default .css_prefix-search.search--open .search__input {
    font-size: var(--font-size-h5);
    padding-right: 4em;
  }

  .search_style_default .css_prefix-search.search--open .search-submit {
    right: 16px;
  }
}