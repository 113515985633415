/**
 * Custom Properties
 * aka CSS variables.
 *
 * @link: https://developer.mozilla.org/en-US/docs/Web/CSS/--*
 **/

@font-face {
	font-family: 'TeXGyreAdventor';
	font-style: normal;
	font-weight: normal;
	font-display: swap;
	src: url('fonts/texgyreadventor-regular-webfont.woff'), 
	url('fonts/texgyreadventor-regular-webfont.woff') format('woff');
  }
  
  
  @font-face {
	font-family: 'TeXGyreAdventor';
	font-style: normal;
	font-weight: normal;
	font-display: swap;
	src: url('fonts/texgyreadventor-bold-webfont.woff'), 
	url('fonts/texgyreadventor-bold-webfont.woff') format('woff');
  }

 :root {

	/* Container */
	--content-width: 87.5em;
	--content-width-sm: 73.125em;
	--sidebar-width: 18.75em;


	/* Typography */
	--global-font-family: 'Roboto', sans-serif;
	--highlight-font-family: 'TeXGyreAdventor', sans-serif;
	--global-font-size: 1em;
	--global-font-line-height: 1.75;
	--global-box-shadow: 0px 24px 84px 0px rgba(0,0,0,0.05);

	/* Page Spacing */
	--global-page-top-spacing: 8.125em;
	--global-page-bottom-spacing: 8.125em;
	--global-page-top-spacing-tablet: 4.375em;
	--global-page-bottom-spacing-tablet: 4.375em;
	--global-page-top-spacing-mobile: 3.125em;
	--global-page-bottom-spacing-mobile: 3.125em;

	/* Custom editor font sizes */
	--font-size-xs: calc(16 / var(--global-font-size) * 1em);
	--font-size-regular: calc(var(--global-font-size) / 16 * 1em);
	--font-size-large: calc(36 / var(--global-font-size) * 1em);
	--font-size-larger: calc(48 / var(--global-font-size) * 1em);

	/* Fontsize */
	--font-size-normal: 0.875em;
	--font-size-body: 1em;
	--font-size-h1: 4.209em;
	--font-size-h2: 3.157em;
	--font-size-h3: 2.369em;
	--font-size-h4: 1.777em;
	--font-size-h5: 1.333em;
	--font-size-h6: 1em;
	--font-size-xs: 0.75em;
	--font-size-18: 1.125em;
	--font-size-20: 1.25em;
	--font-size-40: 2.5em;
	--font-size-45: 2.8125em;
	--font-size-60: 3.75em;

	/* Line-Height */
	--font-line-height-body: 1.75;
	--font-line-height-p: 1.75;
	--font-line-height-h1: 1.3;
	--font-line-height-h2: 1.3;
	--font-line-height-h3: 1.3;
	--font-line-height-h4: 1.3;
	--font-line-height-h5: 1.3;
	--font-line-height-h6: 1.75;

	/* Font-Weight */
	--font-weight-body: 400;
	--font-weight-h1: 700;
	--font-weight-h2: 700;
	--font-weight-h3: 700;
	--font-weight-h4: 700;
	--font-weight-h5: 700;
	--font-weight-h6: 700;

	/* font-weight*/
	--font-weight-light:300;
	--font-weight-regular:400;
	--font-weight-medium:500;
	--font-weight-semi-bold:600;
	--font-weight-bold:700;

	/* Letter spacing */
	--font-letter-spacing-body: 0;
	--font-letter-spacing-link:0.094em;
	--font-letter-spacing-h1: 0;
	--font-letter-spacing-h2: 0;
	--font-letter-spacing-h3: 0;
	--font-letter-spacing-h4: 0;
	--font-letter-spacing-h5: 0;
	--font-letter-spacing-h6: 0;
	--letter-spacing:0.25em;
	--letter-spacing-one: 0.063em;
	--letter-spacing-two: 0.125em;
    --letter-spacing-three: 0.188em;
	--letter-spacing-four: 0.25em;

	/* border-radius */
	--border-radius: 0;
	--border-radius-box: 5px;
	--border-radius-ten: 10px;

	/* z index */
	--z-index-minus:-1;


	/* Colors */
	--primary-color: #285bd4;
	--secondary-color: #0d1e67;
	--title-color: #142149;
	--tertiry-color: #5e7290;
	--white-color: #fff;


	--global-body-bgcolor: #f5f7fd;
	--global-body-lightcolor: #f1f3f5;
	--global-font-subtitle: #de3a53;
	--global-dark-submenu: #7c859b;
	--global-body-lightbg: #f7f7f7;
	--border-color-light: #f2f2f4;
	--border-color-dark: #262f48;
	--border-color-light-opacity: rgba(255,255,255,0.2);
	--border-color-dark-opacity: rgba(82, 95, 129, 0.1);
	--color-link:  #285bd4;
	--color-link-visited: #285bd4;
	--color-link-active:  #285bd4;
	--color-footer-top-dark: #000505;
	--color-footer-copyright-dark: #000202;
	--color-meta-bg: #e8ebf1;
	--color-categoey-bg: #f5f5f5;
	--color-footer-lightbg: #171717;
	--color-post-sticky-bg: #f9f9f9;
	--color-input-placeholder: #7c859b;
	
	--iq-process-step-9: #c0cef0;
	--process-step-bg:#e9efff;
	--quote-color:#ebefff;
	--quote-color-light:#cdd9ff;
    --input-bg-color:#3D4B85;
	--input-border-light: #DEE8FF;
	--price-range-color: #e9eeff;
	--price-range-slider-color: #eaeefb;
	/* Custom editor colors */
	--primary-color-dark:#de2642;
	--primary-color-light:#ffdce1;
	--secondary-color-dark: #fff2b3;
	--secondary-color-light: #fffbe5;
	--color-theme-red: #c0392b;
	--color-theme-green: #25b15f;
	--color-theme-blue: #354B84;
	--color-theme-cyan: #1277C1;
	--color-theme-yellow: #ffd81c;
	--color-theme-black: #000;
	--color-theme-grey: #95a5a6;
	--color-theme-white-light: #7c859b;
	--color-custom-daylight: #97c0b7;
	--color-custom-sun: #eee9d1;
	--footer-theme-dark: #0e1527;

	/* Header editor colors */
	--global-font-active:#2870de;
	--global-font-hover:#2870de;

	--rating-star-active:#DFB300;
	/* footer */
	--footer-bg:#f2f5fe;	
	/* vertical priceing */
	--inactive-icon :#ADB1BA;
	--slick-dot-light:#BECEF2;
	--divider-border:#eee;
	--old-border-color:#eeeeee;
	--contact-input-border:#e1e1e1;
	--icon-box-style-8-border:#e6e7eb;
	--progress-bg:#6f6f6f;
}

@media (max-width: 991px){
    :root {
        --font-size-h1: 3.653em;
        --font-size-h2: 2em;
        --font-size-h3: 1.827em;
		--font-size-h4: 1.700em;
    }
}