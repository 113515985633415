/* old css */
.admin-bar header.header-default {
  top: 32px;
}

.admin-bar header.header-default.menu-sticky,
body.boxed_layout.admin-bar header.header-default.menu-sticky {
  top: 32px;
}

@media (max-width: 782px) {

  .admin-bar header.header-default,
  .admin-bar header.header-default.menu-sticky {
    top: 46px;
  }
}

@media(max-width:767px) {
  .admin-bar header.header-default {
    transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    -ms-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    -webkit-transition: all 0s ease-in-out;
  }

  header.header-default.menu-sticky .main-header {
    position: relative;
  }

}

@media(max-width: 600px) {
  .admin-bar header.header-default.menu-sticky {
    top: 0;
  }
}

/*--------------------------------------------------------------
# Adminbar css
--------------------------------------------------------------*/
body.admin-bar header, body.admin-bar.css_prefix-default-header header {
  top: 2em;
}

body.admin-bar .has-sticky.header-up,
body.admin-bar .header-custom .header-up.has-sticky {
  top: 2em;
}

@media only screen and (max-width: 601px) {
  body.admin-bar header.no-header-layout, body.admin-bar.css_prefix-default-header header {
    top: 2.875em;
  }
}

@media only screen and (min-width: 601px) and (max-width: 782px) {

  body.admin-bar .header-custom .header-up.has-sticky,
  body.admin-bar .has-sticky.header-up,
  body.admin-bar header,
  body.admin-bar.css_prefix-default-header header {
    top: 2.875em;
  }
}

@media (max-width:600px) {

  /* Static adminbar (Not sticky)*/
  body.admin-bar .header-custom .header-up.has-sticky,
  body.admin-bar .has-sticky.header-up {
    top: 0;
  }

  body.admin-bar header {
    top: 2.875em;
  }
}