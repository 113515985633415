@import "_custom-properties.css";

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
/* stylelint-disable */
html {
	box-sizing: border-box;
}

/* stylelint-enable */

/* HTML Tags */


ins {
	background: #fff9c0;
	text-decoration: none;
}

img {
	max-width: 100%;
	height: auto;
}

pre {
	background: #eeeeee;
	padding: 15px;
	border: 1px solid #eeeeee;
}

hr {
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #e0e0e0;
	border-top: 0;
}

blockquote {
	padding: 15px 30px;
	border: 1px solid var(--border-color-light);
	border-radius: 5px;
	border-left: 5px solid var(--primary-color);
	margin-bottom: 30px;
}

blockquote cite {
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	color: var(--primary-color);
}

blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
	margin-bottom: 0;
}

blockquote .blockquote-footer {
	font-style: italic;
	color: #6c757d;
	font-size: 14px;
	margin-top: 10px;
}

blockquote .blockquote-footer cite {
	color: var(--primary-color);
}

blockquote span.text-right {
	text-align: right;
	display: block;
	color: var(--primary-color);
}

p {
	margin: 1em 0;
    line-height: var(--font-line-height-p);
}

/* Lists (Nested) */
ol,
ul {
	padding-left: 25px;
	margin-bottom: 1em;
}

ol li {
	list-style: decimal;
}

ol ol {
	padding-left: 25px;
}

ul li {
	list-style: inherit;
}

/* Definition Lists */
dl dd {
	margin-bottom: 15px;
}

dl dd:last-child {
	margin-bottom: 0;
}

/* Table */
table {
	border: 1px solid #eaeaea;
	width: 100%;
	margin-bottom: 20px;
}

table td,
table th {
	border: 1px solid #eaeaea;
	padding: 5px 8px;
}

/* Form */
label {
	color: var(--title-color);
	margin-bottom: 0;
}

input,
input[type=text],
input[type=email],
input[type=search],
input[type=password],
textarea {
	max-width: 100%;
	width: 100%;
    float: left;
    padding: 0 15px;
    height: 51px;
    line-height: 48px;
    border: 1px solid #eeeeee;
    color: #59597e;
    transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

select {
	border: 2px solid var(--title-color);
	background: var(--white-color);
	line-height: 48px;
	height: 48px;
	padding: 0 10px;
	width: 100%;
	color: #59597e;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

textarea {
	padding: 15px;
	height: 150px;
	line-height: 1.5;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}

input:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=search]:focus,
input[type=password]:focus,
textarea:focus,
select:focus {
	border-color: var(--secondary-color);
	box-shadow: none;
	outline: none;
}

input::-webkit-input-placeholder {
	color: inherit;
}

input::-moz-placeholder {
	color: inherit;
}

input:-ms-input-placeholder {
	color: inherit;
}

textarea::-webkit-input-placeholder {
	color: inherit;
}

textarea::-moz-placeholder {
	color: inherit;
}

textarea:-ms-input-placeholder {
	color: inherit;
}

input[type=email]::-webkit-input-placeholder {
	color: inherit;
}

input[type=email]::-moz-placeholder {
	color: inherit;
}

input[type=email]:-ms-input-placeholder {
	color: inherit;
}


input[type=submit],
.contact-form .cfield input[type=submit],
[type=submit],
.iq-button {
	color: var(--white-color);
	background: var(--secondary-color );
	margin-bottom: 0;
	text-decoration: none;
	height: auto;
	padding: 10px 30px;
	font-size: 1em;
	line-height: 2;
	border: none;
	position: relative;
	display: inline-block;
	width: auto;
	border-radius: var(--border-radius-box);
	cursor: pointer;
	transition: all 0.5s ease-in-out;
	}

input[type=submit]:hover,
.contact-form .cfield input[type=submit]:hover,
.iq-button:hover,
.iq-button:focus {
	color: var(--white-color);
	background: var(--primary-color);
	outline: none;
	text-decoration: none;
}

input[type=checkbox] {
	width: auto;
	margin-right: 10px;
	line-height: 2;
	height: 32px;
}

.error {
	color: var(--color-theme-red) !important;
}