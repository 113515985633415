@import "_custom-properties.css";

.select2-dropdown {
    border-color: var(--border-color-light); 
}

.select2-container .select2-selection--single {
    height: 3.75em;
    border-color: var(--border-color-light);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 3.75em;
    color: var(--tertiry-color);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 3.75em;
    right: 0.625em;
    width: 1.25em;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-width: 0.313em 0.25em 0 0.25em;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-width: 0 0.25em 0.313em 0.25em;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    height: 2.188em;
    border-color: var(--border-color-light);
}

.select2-search--dropdown {
    padding: 0.25em 1em;
}

.select2-container--default .select2-results>.select2-results__options {
    padding: 0.313em 1em;
    max-height: 10.625em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected], 
.select2-container--default .select2-results__option--highlighted[data-selected] {
    background-color: transparent;
    color: var(--primary-color);
}

.select2-container--default .select2-results__option[aria-selected=true], 
.select2-container--default .select2-results__option[data-selected=true] {
    background-color: transparent;
    color: var(--primary-color);
}
.select2-search--dropdown .select2-search__field {
    padding: 0 0.625em;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0 1.875em 0 1em;
}
