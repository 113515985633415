@import "_custom-properties.css";

@media (min-width: 1499px) {
    .container {
        max-width: var(--content-width-sm);
    }

    .css_prefix .container {
        max-width: var(--content-width);
    }
}

@media (min-width: 1300px) {
    .container {
        max-width: var(--content-width-sm);
    }

    .css_prefix .container {
        max-width: var(--content-width);
    }
}

.container-fluid {
    padding: 0 2em;
}

body .container {
    max-width: var(--content-width-sm);
}

body .css_prefix .container,
body .elementor-section.elementor-section-boxed>.elementor-container {
    max-width: var(--content-width);
}

.home.blog .content-area .site-main {
    padding: 150px 0;
}

.content-area .site-main {
    padding: 150px 0;
    display: inline-block;
    width: 100%;
    float: left;
}

.home .content-area .site-main {
    padding: 0;
}

/*breadcrumb*/
.iq-breadcrumb-one, .css_prefix-breadcrumb-one {
    display: block;
    padding: 70px 0 70px;
    z-index: 9;
    position: relative;
    background: var(--global-body-bgcolor);
}

.css_prefix-default-header .css_prefix-breadcrumb-one,
.css_prefix-default-header .iq-breadcrumb-one {
    background: var(--secondary-color);
    padding: 150px 0 100px;
}

.iq-breadcrumb-one, .css_prefix-breadcrumb-two {
    padding: 32px 0;
    z-index: 9;
}

.css_prefix-default-header .iq-breadcrumb-two,
.css_prefix-default-header .css_prefix-breadcrumb-two {
    padding: 45px 0;
}

.iq-breadcrumb .title,
.iq-breadcrumb-one .title, .css_prefix-breadcrumb .title,
.css_prefix-breadcrumb-one .title {
    color: var(--secondary-color);
    margin: 0;
}

.css_prefix-default-header .iq-breadcrumb .title,
.css_prefix-default-header .css_prefix-breadcrumb .title,
.css_prefix-default-header .css_prefix-breadcrumb-one .title {
    color: var(--white-color);
}

.iq-breadcrumb nav, .css_prefix-breadcrumb .css_prefix-breadcrumb-nav {
    display: inline-block;
    margin-top: 0;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 9px;
}

.breadcrumb-item+.breadcrumb-item::before {
    padding-right: 15px;
}

.iq-breadcrumb.text-left .breadcrumb, .css_prefix-breadcrumb.text-left .breadcrumb {
    z-index: 86;
    text-align: left;
    position: relative;
    color: var(--white-color);
    margin-bottom: 0;
    display: inline-block;
    width: 100%;
    padding: 10px 0;
    background: none;
}

.iq-breadcrumb.text-left .breadcrumb li, .css_prefix-breadcrumb.text-left .breadcrumb li {
    display: inline-block;
    word-break: break-all;
}

.iq-breadcrumb.text-left .breadcrumb li a i, .css_prefix-breadcrumb.text-left .breadcrumb li a i {
    font-size: var(--font-size-18);
    margin-right: 6px;
}

.iq-breadcrumb.text-left .breadcrumb li,
.iq-breadcrumb.text-left .breadcrumb li a:hover, .css_prefix-breadcrumb.text-left .breadcrumb li,
.css_prefix-breadcrumb.text-left .breadcrumb li a:hover {
    color: var(--white-color);
    text-decoration: none;
}

.iq-breadcrumb.text-left .breadcrumb li a,
.iq-breadcrumb.text-left .breadcrumb-item+.breadcrumb-item::before .css_prefix-breadcrumb.text-left .breadcrumb li a,
.css_prefix-breadcrumb.text-left .breadcrumb-item+.breadcrumb-item::before {
    color: var(--white-color);
    margin-bottom: 0;
}

.breadcrumb li {
    display: inline-block;
    word-break: break-all;
}

.iq-breadcrumb-one ol li, .css_prefix-breadcrumb-one ol li {
    font-size: var(--font-size-normal);
    list-style: none;
    display: inline-block;
    color: var(--primary-color);
    text-transform: uppercase;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: var(--letter-spacing-one);
}

.iq-breadcrumb-one ol li a, .css_prefix-breadcrumb-one ol li a {
    color: var(--tertiry-color);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-one);
    font-weight: var(--font-weight-semi-bold);
}

.css_prefix-default-header .iq-breadcrumb-one ol li a,
.css_prefix-default-header .css_prefix-breadcrumb-one ol li a {
    color: var(--white-color);
}


.iq-breadcrumb-one .breadcrumb-item+.breadcrumb-item::before, .css_prefix-breadcrumb-one .breadcrumb-item+.breadcrumb-item::before {
    content: "\f3d3";
    color: var(--tertiry-color);
    font-family: "Ionicons";
}

.css_prefix-default-header .css_prefix-breadcrumb-one .breadcrumb-item+.breadcrumb-item:before,
.css_prefix-default-header .iq-breadcrumb-one .breadcrumb-item+.breadcrumb-item:before {
    color: var(--white-color);
}

.iq-breadcrumb-one .breadcrumb, .css_prefix-breadcrumb-one .breadcrumb {
    position: relative;
    display: inline-block;
}

.iq-breadcrumb-one .breadcrumb-item.active,
.iq-breadcrumb-one ol li a:hover, .css_prefix-breadcrumb-one .breadcrumb-item.active, .css_prefix-breadcrumb-one ol li a:hover {
    color: var(--primary-color);
    position: relative;
}

.iq-breadcrumb-one ol, .css_prefix-breadcrumb-one ol {
    background: transparent;
    padding: 0;
    margin-bottom: 0;
    margin-top: 20px;
}

.iq-breadcrumb-two .breadcrumb, .css_prefix-breadcrumb-two .breadcrumb {
    display: inline-block;
}

.iq-breadcrumb-two ol li, .css_prefix-breadcrumb-two ol li {
    display: inline-block;
}

.iq-breadcrumb-three .breadcrumb, .css_prefix-breadcrumb-three .breadcrumb {
    margin-top: 0;
}

.iq-breadcrumb-three .iq-breadcrumb-one, .css_prefix-breadcrumb-three .css_prefix-breadcrumb-one {
    padding: 0;
}

.iq-breadcrumb-three, .css_prefix-breadcrumb-three {
    padding: 45px 0;
}

.breadcrumb-bg,
.breadcrumb-video,
.video-iq-bg-over {
    position: relative;
    overflow: hidden;
}

.breadcrumb-bg video,
.breadcrumb-bg #video-background,
.breadcrumb-video video,
.video-iq-bg-over video,
.video-breadcrumb-bg #video-background,
.video-iq-bg-over #video-background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 100%;
}

.breadcrumb-bg.iq-over-dark-80:before,
.breadcrumb-ui:before {
    z-index: 0;
}

.breadcrumb-bg {
    position: relative;
}

.breadcrumb-bg:before,
.breadcrumb-ui:before,
.breadcrumb-video::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgba(2, 13, 30, 0.9);
    display: block !important;
}

.breadcrumb-video::before {
    z-index: 1;
}

/*Breadcrumb Responsive*/

@media(max-width:1199px) {
    .iq-breadcrumb-three, .css_prefix-breadcrumb-three {
        padding: 45px 15px;
    }
}

@media(max-width:991px) {
    .iq-breadcrumb-one, .css_prefix-breadcrumb-one {
        padding: 60px 16px 60px;
    }

    .css_prefix-default-header .iq-breadcrumb-one, .css_prefix-default-header .css_prefix-breadcrumb-one {
        padding: 30px 0px;
    }

    .css_prefix-default-header .iq-breadcrumb-two, .css_prefix-default-header .css_prefix-breadcrumb-two {
        padding: 100px 15px 60px;
    }

    .iq-breadcrumb .title, .iq-breadcrumb-one .title, .css_prefix-breadcrumb .title .css_prefix-breadcrumb-one .title {
        font-size: 40px;
    }

    .iq-breadcrumb .title, .css_prefix-breadcrumb .title {
        font-size: 38px;
    }
}

@media(max-width:767px) {
    .iq-breadcrumb.text-left, .iq-breadcrumb .text-left, .css_prefix-breadcrumb.text-left, .css_prefix-breadcrumb .text-left {
        text-align: center !important;
    }

    .iq-breadcrumb .float-right, .css_prefix-breadcrumb .float-right {
        float: none !important;
    }

    .iq-breadcrumb .title, .css_prefix-breadcrumb .title {
        font-size: 32px;
    }

    .iq-breadcrumb.text-left .breadcrumb li, .iq-breadcrumb.text-left .breadcrumb li a, .css_prefix-breadcrumb.text-left .breadcrumb li, .css_prefix-breadcrumb.text-left .breadcrumb li a {
        font-size: 14px;
    }

    .iq-breadcrumb .title, .iq-breadcrumb-one .title, .css_prefix-breadcrumb .title, .css_prefix-breadcrumb-one .title {
        font-size: 36px;
    }

    .iq-breadcrumb-one, .css_prefix-breadcrumb-one {
        padding: 60px 0;
    }
}

@media(max-width:479px) {
    .iq-breadcrumb .title, .iq-breadcrumb-one .title, .css_prefix-breadcrumb .title, .css_prefix-breadcrumb-one .title {
        font-size: 30px;
    }
}

@media (max-width: 1024px) {
    .content-area .site-main {
        padding: 4.375em 1em;
    }

    .home.blog .css_prefix .content-area .site-main {
        padding: 4.375em 1em;
    }
}

@media (max-width: 767px) {
    .content-area .site-main {
        padding: 3.125em 1em;
    }

    .home.blog .css_prefix .content-area .site-main {
        padding: 3.125em 1em;
    }
}