/*--------------------------------------------------------------
                           Footer
--------------------------------------------------------------*/
footer {
	display: block;
	width: 100%;
	float: left;
	position: relative;
	clear: both;
	margin-top: 0;
}

footer.css_prefix-footer {
	background: var(--footer-bg);
}

footer .copyright-footer {
	border-top: 1px solid #59597e;
	font-size: var(--font-size-normal);
}

footer .copyright-footer a:hover {
	color: var(--secondary-color);
}

footer .footer-top {
	padding-top: 60px;
}

footer .footer-top .container {
	padding: 0 15px;
}

/*footer top bar*/
footer .footer-titlebox {
	margin-bottom: 30px;
	position: relative;
}

footer .footer-topbar {
	position: relative;
	z-index: 1;
	padding: 40px 15px;

}

footer .footer-topbar .widget.widget_text .textwidget h2 {
	color: var(--secondary-color) !important;
}

footer .footer-topbar p {
	margin-bottom: 0;
}

footer .footer-topbar .widget,
footer .footer-topbar .widget:last-child {
	margin-bottom: 0;
}

/* Footer 1 */
footer .footer1 {
	padding: 0;
}

footer .footer-top .widget ul li a:hover {
	color: var(--primary-color);
}

footer .footer-top .widget.widget_categories ul li.cat-item a:hover {
	color: var(--primary-color);
}

footer .widget.widget_block .wp-block-tag-cloud a:hover,
footer .widget_tag_cloud ul li a.tag-cloud-link:hover {
	color: var(--white-color);
	background-color: var(--primary-color);
}


footer .widget_archive ul li span.archiveCount, footer .widget_categories ul li span {
	color: #59597e;
}

footer.css_prefix-footer .footer-logo img {
	margin-bottom: 30px;
}


.footer-top .textwidget p {
	margin-bottom: 0;
}

footer .mc4wp-form-fields {
	float: left;
	width: 100%;
}

footer .footer-top .mc4wp-form button {
	font-size: 0;
	position: absolute;
	right: 0;
	top: 15px;
	background: transparent;
	border: 0;
	padding: 0;
}

footer .footer-top .mc4wp-form button i {
	font-size: var(--font-size-20);
}

footer .footer-top .mc4wp-form p {
	margin-bottom: 0;
}

footer .social-icone li {
	margin-right: 20px;
}

footer .social-icone li:last-child {
	margin-right: 0;
}

footer .widget,
footer .widget:last-child {
	margin-bottom: 45px;
}

footer .widget {
	background: transparent;
	padding: 0;
}

footer .widget .footer-title {
	margin-bottom: 15px;
	color: var(--title-color);
}

footer .widget .footer-title a.rsswidget img {
	vertical-align: unset;
}

footer .widget .rsswidget {
	padding-left: 0;
	font-size: var(--font-size-body);
}

footer .widget .rss-date, footer .widget .rsswidget {
	font-weight: var(--font-weight-semi-bold);
}

footer .widget .rss-date {
	font-size: var(--font-size-xs);
	text-transform: uppercase;
	display: inline-block;
	width: 100%;
	margin: 5px 0;
}

footer .widget .rsswidget:before {
	display: none;
}

footer .widget ul.menu li .sub-menu {
	padding-left: 10px;
}

footer .widget #recentcomments li .comment-author-link a:hover {
	color: var(--secondary-color);
}

.recentcomments a {
	display: inline !important;
	padding: 0 !important;
	margin: 0 !important;
}

footer .widget #recentcomments li a,
.recentcomments a {
	color: var(--secondary-color);
}

footer .widget #recentcomments li a:hover {
	text-decoration: underline;
}

footer .widget #recentcomments li a:before {
	display: none;
}

footer.footer1 .copyright {
	float: right;
}

footer.footer1 .widget ul.menu li a {
	border: none;
}

footer .widget .iq-contact li a:before {
	display: none;
}

footer ul.menu li a,
footer .menu-footer-menu-container ul.menu li a {
	padding: 7px 0 7px 15px;
}

footer .widget .menu li a:before,
footer .widget.widget_nav_menu ul li a:before,
footer .widget ul.menu li a:before,
footer .widget .menu-testing-menu-container .menu ul li a::before,
footer .widget .menu-testing-menu-container .menu li a::before {
	display: block;
}

footer .widget .menu-testing-menu-container .menu ul li a,
footer .widget .menu-testing-menu-container .menu li a {
	position: relative;
	padding: 7px 0 7px 15px;
}

footer .widget .iq-contact li a {
	padding: 7px 0;
}

footer .widget .iq-contact li {
	position: relative;
	margin-bottom: 0;
	display: inline-block;
	width: 100%;
	border-bottom: none;
	padding-bottom: 0;
}

footer .widget .iq-contact li a {
	display: flex;
	align-items: flex-start;
}

footer .widget .iq-contact li span {
	flex: 1;
}

footer .widget .iq-contact li i {
	margin-right: 15px;
	line-height: 32px;
	font-size: var(--font-size-20);
	text-align: center;
}

footer .widget .iq-contact li p {
	display: flex;
	margin-bottom: 0;
}

footer .widget ul.menu li a,
footer .widget.widget_nav_menu ul.menu li a {
	border-bottom: none;
	padding: 7px 0 7px 15px;
}

/* tags */

footer .footer-top .widget .wp-block-tag-cloud ul li a.tag-cloud-link:hover {
	color: var(--white-color);
}

footer.wp-block-latest-comments__comment-meta {
	background: transparent;
}

/*================
	Responsive
================*/

@media (max-width: 1215px) {
	footer .footer-top .container {
		padding: 0 30px;
	}

	footer .copyright-footer {
		padding: 0 15px;
	}
}


@media (max-width: 1199px) {
	footer .footer-topbar {
		padding: 50px 15px 30px 15px;
	}
}

@media (max-width: 1024px) {
	footer .footer-topbar {
		padding: 50px 0 30px 0;
	}

	footer .footer-top .container {
		padding: 0 15px;
	}

	footer .widget {
		padding: 0 15px;
	}
}

@media (max-width: 767px) {
	.copyright-footer {
		text-align: center !important;
	}

	.footer-subscribe .vc_custom_heading {
		margin-bottom: 15px;
	}

	.copyright-footer .col-auto {
		display: inline-block;
		width: 100%;
	}

	footer .footer-topbar {
		width: 100%;
		margin: 0 auto;
	}
}

@media (max-width: 575px) {

	footer .footer-top .row>div .widget {
		margin-bottom: 15px;
	}

	footer .footer-top .row>div:last-child .widget {
		margin-bottom: 45px;
	}
}